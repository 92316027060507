import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
const Header = () => {
  const controlNavbar = () => {
    if (window.scrollY) {
      document.getElementById("headerId").classList.add("darker-header");
    }
    if (window.scrollY === 0) {
      document.getElementById("headerId").classList.remove("darker-header");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  /* ====*/
  const navigate = useNavigate();

  const handleClicktoTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <header className="heading" id="headerId">
      <Navbar expand="lg" style={{ padding: 0 }}>
        <Container>
          <Link to="/">
            <img
              src="/assets/images/logo.png"
              className="img-fluid navBarLogo"
              alt="logo"
            />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <div className="buttonContainer d-flex gap-2">
                <Link
                  className="buttonEarlyAccess"
                  to="/"
                  onClick={handleClicktoTop}
                >
                  Get Early Access
                </Link>
                <Link className="buttonEarlyAccess" to="/login">
                  Sign In
                </Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
