import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import PrivacyPolicy from "./pages/Homepage/PrivacyPolicy";
import TermAndCondition from "./pages/Homepage/TermAndCondition";
import NotFound from "./pages/PageNotFound/NotFound";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />

      <Route path="/terms_conditions" element={<TermAndCondition />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to={"/404"} />} />
    </Routes>
  );
}

export default App;
